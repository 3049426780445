import "./Footer.scss"

const Footer = () => {
  return (
    <>
    
    <hr />
      <div className="footer">
        <div className="footerLeft">
            <h1>Let's make something
                              <br />
            amazing together.</h1>
            <h1 className="start">Start by <a href="#">saying hi</a></h1>
        </div>
        <div className="footerRight">
            <h1>Information</h1>
            <p>145 New York, FL 5467, USA</p>
            <a href="#">Services</a>
            <a href="#">Works</a>
            <a href="#">Notes</a>
            <a href="#">Experience</a>
        </div>
      </div>

      
    </>
  )
}

export default Footer