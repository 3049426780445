import "./HeroSection.scss"

const HeroSection = () => {
  return (
    <div className='heroSection'>
        <div className="heroLeft">
          <h1>Hey There,
            <br />
            I'm Sakin.</h1>
          <a href="#">zainkeepscode@gmail.com</a>
          <div className="heroLeftTitle">
            <h1>10</h1>
            <h3>Years 
              <br />
               Experience</h3>
          </div>
        </div>
        <div className="heroCenter">
          <img 
          className='heroCenterImg'
          src="https://youtubebinjanportfolio.pages.dev/person.png" alt="" />
        </div>
        <div className="heroRight">
           <div className="heroRigthTitle">
            <h4>I design beautiful simple
              <br />
              <br />
             things, And I love what i do</h4>
           </div>
           <div className="heroRigthBotttom">
            <img 
            className='rightBottomImg'
            src="https://youtubebinjanportfolio.pages.dev/certificate.png" alt="" />
            <h5>CERTIFIED PROFATIONAL</h5>
                <h5>UI/UX DESIGNER</h5>
           </div>
        </div>
      </div>
  )
}

export default HeroSection