import React from 'react'
import"./Home.scss"
import Header from '../../components/Header/Header'
import HeroSection from '../../components/HeroSection/HeroSection'
import Service from '../Service/Service'
import Experiance from '../Experiances/Experiance'
import Portfolio from '../Portfolio/Portfolio'
import Footer from '../../components/Footer/Footer'

const Home = () => {
  return (
    <>
      <Header/>
      <HeroSection/>
      <Service/>
      <Experiance/>
      <Portfolio/>
      <Footer/>
    </>
  )
}

export default Home