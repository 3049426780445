import "./Experiance.scss"

const Experiance = () => {
  return (
    <>

    <div id="experiance" className="experiance">
        <div className="expHeader">
            <h1>My Work Experience</h1>
        </div>

        <div className="expBody">
            <div className="expBodyLeft">
                <div className="expBodyLeftSec1">
                    <h3>Self-Employed, Brisbane</h3>
                    <p>Aug 2014 - Sep 2016</p>
                </div>
                <div className="expBodyLeftSec2">
                    <h3>New Man Services</h3>
                    <p>Aug 2014 - Sep 2016</p>
                </div>
                <div className="expBodyLeftSec3">
                    <h3>Global Solution</h3>
                    <p>Aug 2014 - Sep 2016</p>
                </div>
            </div>

            <div className="expBodyCenter">
                <p>|</p>
                <p>|</p>
                <i className="fa-solid fa-circle circleIcon1"></i>
                <p>|</p>
                <p>|</p>
                <p>|</p>
                <i className="fa-solid fa-circle circleIcon2"></i>
                <p>|</p>
                <p>|</p>
                <p>|</p>
                <p>|</p>
                <i className="fa-solid fa-circle circleIcon3"></i>
                <p>|</p>
                <p>|</p>
            </div>

            <div className="expBodyRight">
                <div className="expBodyRight1">
                    <h4>Visual Designer</h4>
                    <p>A visual desginer dsesign for a variety of platoforms, may include internet and internet sites, games, movies, kioasks and wearbies. In short, they create the concepts</p>
                </div>
                <div className="expBodyRight2
                ">
                    <h4>UI/UX Designer</h4>
                    <p>A visual desginer dsesign for a variety of platoforms, may include internet and internet sites, games, movies, kioasks and wearbies. In short, they create the concepts</p>
                </div>
                <div className="expBodyRight3">
                    <h4>Sr. Product Designer</h4>
                    <p>A visual desginer dsesign for a variety of platoforms, may include internet and internet sites, games, movies, kioasks and wearbies. In short, they create the concepts</p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Experiance