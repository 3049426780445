import "./Service.scss"

const Service = () => {
  return (
    <>
    <div id="service" className="service">

        <div className="serviceLeft">

            <div className="serviceWeb">
            <div class="card">
                <div class="card-body">
                    <div className="cardLogoOne">
                    <i class="fa-solid fa-computer"></i>
                    </div>
                    <div className="cardTitle">
                        <h2>Website Design</h2>
                        <h6>76 Projects</h6>
                    </div>
                </div>
            </div>
            </div>

            <div className="serviceApp">
            <div class="card">
                <div class="card-body">
                    <div className="cardLogoTwo">
                    <i class="fa-sharp fa-solid fa-mobile"></i>
                    </div>
                    <div className="cardTitle">
                        <h2>Mobile App Design</h2>
                        <h6>63 Projects</h6>
                    </div>
                </div>
            </div>
            </div>
            <div className="serviceBrand">
            <div class="card">
                <div class="card-body">
                    <div className="cardLogoThree">
                    <i class="fa-solid fa-code-branch"></i>
                    </div>
                    <div className="cardTitle">
                        <h2>Brand Identity</h2>
                        <h6>47 Projects</h6>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div className="serviceRigth">
            <div className="serviceTitle">
                <h1>What do I help?</h1>
            </div>
            <div className="serviceDesc">
                <p>I will help you with finging a solution and solve your
                    <br />
                    problem, We use process design to create digital 
                    <br />
                    products. Besides that also help their business.
                    <br />
                    <br />
               We use process design to create digital products.
               <br />
                Besides that also help their    business</p>
            </div>
            <div className="serviceNbr">
                <div className="serviceNbrOne">
                    <h1>385+</h1>
                    <p>Projects Completed</p>
                </div>
                <div className="serviceNbrSecond">
                    <h1>190+</h1>
                    <p>Happy Clients</p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Service