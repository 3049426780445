import "./Header.scss"

const Header = () => {
  return (
    <>
     <div className="header">
      <div className="headerLeft">
        <h3>SAKIN</h3>
      </div>
      <div className="headerCeneter">
        <ul className="topbar" type="none">
          <li className="topbarItem"> <a href="#service">SERVICES</a></li>
          <li className="topbarItem"> <a href="#experiance">EXPERIENCE</a></li>
          <li className="topbarItem"> <a href="#portfolio">PORTFOLIO</a></li>
          <li className="topbarItem"> <a href="#testimonial">TESTIMONIALS</a></li>
        </ul>
      </div>
      <div className="headerRigth">
          <p>+001(313)354687</p>
          <i class="fa-brands fa-whatsapp"></i>
      </div>
     </div>
    </>
  )
}

export default Header