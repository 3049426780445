import "./Portfolio.scss"

const Portfolio = () => {
  return (
    <>
     <div id="portfolio" className="portfolio">
        <div className="portTop">
            <div className="portTopLeft">
                <h1>My Latest Works</h1>
                <p>Perfect solution for digital experience</p>
            </div>
            <div className="portTopRight">
                <a href="#">Explore More Works</a>
            </div>
        </div>

        <div className="portBottom">
            <img 
            className="portBottom1"
            src="https://youtubebinjanportfolio.pages.dev/showCase1.png" alt="" />
            <img 
            className="portBottom2"
            src="https://youtubebinjanportfolio.pages.dev/showCase2.png" alt="" /><img 
            className="portBottom3"
            src="https://youtubebinjanportfolio.pages.dev/showCase3.png" alt="" />
        </div>
     </div>
    </>
  )
}

export default Portfolio